.header-container{
    font-size: 15px;
    width: 100vw;
    height: 60px;
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    z-index: 1000;
    overflow: hidden;
    top: 0;
}
 .header-container .logo{
     display: flex;
     justify-content: space-between;
     margin: .4em 2em;
 }

 .logo img{
     height: 2.5em;
     width: auto;
 }

 .logo h1{
     color: white;
     font-family: Arial;
     font-size: 1.4em;
     font-weight: 900;
     margin-left: .8em;
     margin-top: .2em;
     letter-spacing: -.1em;
 }
 .menu{
    margin-right: 2.5em;
    display: flex;

 }

 .menu a{
     color: white;
     margin: 1.5em;
     font-size: 1em;
     font-weight: 700;
 }

 .menu a:hover{
     background-color: rgba(0, 0, 0, .3);
 }

 .btn-menu-mobil{
    display: none;
    color: white;
    font-size: 1.3em;
    font-weight: 800;
    margin-right: 2em;
    background-color: transparent;
    border: none;
    transition: .5s;
}

.header-menu-mobil{
    display: none;
}
.scroll-down{
    transform: translate3d(0, -100%, 0);
  }
  
.scroll-up{
    transform: none;
  }

@media screen and (max-width: 1280px){
    .header-container{
        font-size: 15px;
    }
}

@media screen and (max-width: 1024px){
    .header-container{
        font-size: 14.5px;
    }
}

@media screen and (max-width: 835px){
    .header-container{
        font-size: 13.8px;
    }
    .menu a{
        font-weight: 900;
    }
}

 @media screen and (max-width: 767px){
    .header-container{
        font-size: 12.5px;
    }
    .menu{
        display: none;
    }
    .btn-menu-mobil{
        display: block;
    }
    .menu-mobil.active{
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 53px;
        right: 0px;
        width: 100%;
        height: 100vh;
        background-color: rgba(0, 0, 0, .8);
        font-size: 1.2em; 
        text-align: left;
        margin: 0;
        margin-bottom: 1em;
        overflow: scroll;
    }
    .menu.menu-mobil.active a{
        margin: .4em auto;
        padding-bottom: 1em;
        width: 90%;
        border-bottom: 1px solid rgba(1111, 1111, 1111, .5);
    }
    .menu-container{
        display: flex;
        flex-direction: column;
        margin-top: 4em;
    }
    .header-menu-mobil.active{
        display: block;
        color: white;
        font-size: 1em;
        align-self: center;
        margin: 12em .5em;
        font-style: italic;
    }
    .btn-menu-mobil.close{
        font-size: 1.5em;
        color: white;
        font-weight: 800;
        margin-right: 1.7em;
        border: 1px solid white;
        border-radius: 50%;
        padding: .2em .5em;
        transform: rotateX(180deg);
        transition: .5s;
    }
}
@media screen and (max-width: 480px){
    .header-container{
        font-size: 12px;
    }
    .btn-menu-mobil{
        display: block;
    }
}

@media screen and (max-width: 320px){
    .header-container{
        font-size: 10px;
    }
    .btn-menu-mobil{
        display: block;
    }
}
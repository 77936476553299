.main-container{
    font-size: 15px;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    overflow: hidden;
    background: #1D4350;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #A43931, #1D4350);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #A43931, #1D4350); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.main-title{
    color: white;
    font-size: 8em;
    font-family: 'Arial';
    font-weight: 900;
    line-height: .75em;
}

.planets-container{
    font-size: 15px;
}

.planets-container img{
    height: 18em;
    width: auto;
} 

.juno,
.saturn{
    position: relative;
}

.juno {
    animation: Juno-spin infinite 8s alternate ease-in-out;  
}

.saturn{
    animation: Saturn-spin infinite 8s alternate-reverse ease-in-out;
}

.orbita-cool{
    z-index: 200;
}

@keyframes Juno-spin {
    from {
        transform: translate(12em, 5em)  scale(1.3);
    } to {
        transform: translate(30em, 5em) scale(.5);
    }
  }

  @keyframes Saturn-spin {
    from {
        transform: translate(-5em, 1em) scale(.7);       
    }
    to {
        transform: translate(14em, 1em) scale(1.3);
    }
  }

@media screen and (orientation: landscape){
    .main-container{
        font-size: 2vmin;
    }
    .planets-container{
        font-size: 2vmin;
    }
}
@media screen and (orientation: portrait){
    .main-container{
        font-size: 2vmin;
    }
    .planets-container{
        font-size: 2vmin;
    }
}
/* @media screen and (max-width: 1280px){
    .planets-container{
        font-size: 14px;
    }
    .main-container{
        font-size: 15px;
    }
}
@media screen and (max-width: 1180px){
    .planets-container{
        font-size: 13.5px;
    }
    .main-container{
        font-size: 14px;
    }
}
@media screen and (max-width: 1080px){
    .planets-container{
        font-size: 12px;
    }
    .main-container{
        font-size: 13px;
    }
}
@media screen and (max-width: 1024px){
    .planets-container{
        font-size: 12.5px;
    }
    .main-container{
        font-size: 12.5px;
    }
}
@media screen and (max-width: 835px){
    .planets-container{
        font-size: 12px;
    }
    .main-container{
        font-size: 12px;
    }
}
@media screen and (max-width: 767px){
    .main-container{
        font-size: 10.5px;
    }
    .planets-container{
        font-size: 10px;
    }
}
@media screen and (max-width: 480px){
    .main-container{
        font-size: 8px;
    }
    .planets-container{
        font-size: 7px;
    }
}
@media screen and (max-width: 320px){
    .main-container{
        font-size: 6.5px;
    }
    .planets-container{
        font-size: 5.5px;
    }
} */
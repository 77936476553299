*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Arial';
  text-decoration: none;
  outline: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  color: white;
}

a{
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

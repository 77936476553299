.photos-container{
    display: flex;
    font-size: 16px;
    margin: 15px auto;
    max-width: 80%;
    flex-wrap: wrap;
    justify-content: center;
}

.photo{
    height: 18.5em;
    width: 18.5em;
    margin: 5px;
    padding: 5px;
    cursor: pointer;
}

.photos-container.view{
    display: flex;
    flex-direction: column;
    min-width: 100vw;
   margin: 20px 0px;
}
.photo.view{
    width: 100%;
    height: auto;
    margin: 5px 0px;
    padding: 5px 0px;
    cursor: pointer;
    object-fit: cover;
}

@media screen and (max-width: 1280px){
    .photos-container{
        font-size: 15px;
        max-width: 85%;
    }
}
@media screen and (max-width: 1024px){
    .photos-container{
        font-size: 13.5px;
        max-width: 95%;
    }
}
@media screen and (max-width: 835px){
    .photos-container{
        font-size: 12.5px;
    }
}
@media screen and (max-width: 767px){
    .photos-container{
        font-size: 8.5px;
        max-width: 100%;
    }
    .photo{
        min-width: 33.3%;
        height: 33.3vw;
        margin: 0px;
        padding: .5px;
    }
}
@media screen and (max-width: 480px){
    .photos-container{
        font-size: 5px;
        max-width: 100%;
    }
    .photo{
        min-width: 33.3%;
        height: 33.3vw;
        margin: 0px;
        padding: .5px;
    }
}
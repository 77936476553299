body{
    overflow-x: hidden;
    background-color: black;
}

.image-history{
    position: relative;
    height: 32em;
    width: auto;
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.title-history-page{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.title-history-page h1{
    color: white;
    font-size: 4.2em;
    margin: auto;
}

.subs{
    color: white;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.subs h3{
    font-size: .9em;
    margin: 0em 7vw;
}

.text-history-container{
    height: auto;
    width: 80vw;
    background-color: black;
    color: white;
    margin: auto;
}

.text-history-container h2{
    display: flex;
    justify-content: center;
    margin-top: 5em;
    margin-bottom: 3em;
    font-size: 1.5em;
}

.text-history-container span{
    padding-bottom: 25vh;
    font-size: .6em;
    text-align: justify;
}

.text-footer{
    text-align: right;
}

.height{
    height: 50vh;
}

.planets-container{
    font-size: 15px;
    margin-bottom: 100px;
    max-width: 100vw;
}

.planets-container img{
    width: 10em;
    height: auto;
} 

.juno,
.saturn{
    position: relative;
}

.juno {
    animation: Juno-spin infinite 4s alternate ease-in-out;  
}

.saturn{
    animation: Saturn-spin infinite 4s alternate-reverse ease-in-out;
}

.orbita-cool{
    z-index: 200;
}

@keyframes Juno-spin {
    from {
        transform: translate(2em, 3em)  scale(1);
    } to {
        transform: translate(15em, 5em) scale(.8);
    }
  }

  @keyframes Saturn-spin {
    from {
        transform: translate(2em, -5em) scale(1.3);       
    }
    to {
        transform: translate(10em, -5em) scale(.8);
    }
  }


@media screen and (orientation: landscape){
    .image-history{
        font-size: 2.5vmin;
        -webkit-box-shadow: inset 0px -28vmin 100px -40px rgba(0,0,0,1);
        -moz-box-shadow: inset 0px -28vmin 100px -40px rgba(0,0,0,1);
        box-shadow: inset 0px -22vmin 6em -3em rgba(0,0,0,.98);
    }
    .title-history-page{
        font-size: 3vmin;
    }
    .text-history-container{
        font-size: 5vmin;
    }
}
@media screen and (orientation: portrait){
    .image-history{
        font-size: 5vmin;
        -webkit-box-shadow: inset 0px -22vmin 4em -1.5em rgba(0,0,0,1);
        -moz-box-shadow: inset 0px -22vmin 4em -1.5em rgba(0,0,0,1);
        box-shadow: inset 0px -22vmin 6em -1.5em rgba(0,0,0,1);
    }
    .title-history-page{
        font-size: 3vmin;
    }
    .subs{
        font-size: 3.5vmin;
    }
    .text-history-container{
        font-size: 7.5vmin;
    }
}

@media screen and (max-width: 480px){
    .planets-container img{
        height: 7em;
        width: auto;
    } 
    @keyframes Juno-spin {
        from {
            transform: translate(4em, 3em)  scale(1);
        } to {
            transform: translate(15em, 3em) scale(1);
        }
      }
    
      @keyframes Saturn-spin {
        from {
            transform: translate(-4em, 3em) scale(1.1);       
        }
        to {
            transform: translate(6em, 3em) scale(1);
        }
      }
    
}
.gallery-header{
    margin: auto;
    margin-top: 5.5em;
    display: flex;
    justify-content: center;
    font-size: 16px;
}

.gallery-header .gallery-profile{
    margin-right: 8em;
    align-self: flex-start;
}

.gallery-profile img{
    height: 10em;
    width: 10em;
    border-radius: 50%;
}

.gallery-header .description{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 15em;
}

.gallery-header .description h1{
    font-weight: 300;
    font-size: 2.7em;
}

.gallery-header .description h2{
    font-weight: 900;
    font-size: 1em;
}

.gallery-header .description .profit{
    font-size: 1em;
    display: flex;
    max-width: 30em;
}

.description-mobil{
    display: none;
    font-size: calc(10px + .9vmin);
}

.description-mobil h2{
    margin-top: 15px;
    margin-bottom: 5px;
}

hr{
    display: flex;
    margin: auto;
    margin-top: 1em;
    width: 65vw;
    border: 1px solid rgba(1111, 1111, 1111, .3);
}

@media screen and (max-width: 1280px){
    .gallery-header{
        font-size: 15.5px;
    }
    .gallery-header .description{
        font-size: 16px;
    }
}

@media screen and (max-width: 835px){
    .gallery-header{
        font-size: 14.5px;
    }
    .gallery-header .gallery-profile{
        font-size: 14.5px;
        margin-right: 4em;
    }
    .gallery-header .description{
        font-size: 15px;
    }
}

@media screen and (max-width: 767px){
    .gallery-header{
        font-size: 13px;
        
    }
    .gallery-header .description .mobil {
        display: none;
    }
    .gallery-header .gallery-profile{
        align-self: center;
        margin-left: 3.5em;
        margin-right: 15vw;
    }
    .gallery-header .description{
        margin: auto;
        margin-left: 0;
        height: auto;
    }
    .description-mobil{

        display: block;
        max-width: 85%;
        margin: auto;
        margin-top: .8em;
    }
}

@media screen and (max-width: 480px){
    .gallery-header{
        max-width: 90%;
        margin: auto;
        margin-top: 5.5em;
    }
    .gallery-header .gallery-profile{
        font-size: 11.5px;
        margin-right: 2.5em;
        margin-left: 1.5em;
    }
    .gallery-header .description{
        font-size: 12px;
    }

}